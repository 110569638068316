import React, {useEffect, useState, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { StairazAuth } from '../components/userAuth';
import { generateClient } from 'aws-amplify/api';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import {subscriptionStatusesByUserprofileID} from '../graphql/queries';
import Layout from '../components/layout';
import { useTheme } from '../context/ThemeContext';
import { fetchUserAttributes} from "@aws-amplify/auth";
import planPrice from '../contents/price/planPrice.json';
import * as styles from "../styles/checkout.module.css"; // Import your CSS styles
import { FaCheckCircle } from 'react-icons/fa';
// Load the Stripe object outside of the component’s render to avoid recreating it on every render
const stripePromise = loadStripe("pk_live_51PJKebEI7aDvf2Y5EHVgCZ1RBXBhpVAf07Z50IjfyoujOFLTmfQcDC3SKiYvW02g526O0dWf75d0fHukRU4uPHeS000dCjZeED");

const CheckoutForm = ({ user }) => {
    const { pricingResume } = planPrice;
    const { isDarkMode } = useTheme();
    const client = generateClient()
    const [subscriptionStatus,setSubscriptionStatus] = useState(null)
    const [options,setOptions] = useState(null)

    const urlParams = new URLSearchParams(window.location.search);
    const lookupKey = urlParams.get('lookup_key');
    const paymentMode = urlParams.get('payment_mode');
    const prodId = urlParams.get('prod_id');

    const handleLookupKeyChange = (newKey) => {
        const newUrl = `/checkout?lookup_key=${newKey}&payment_mode=${paymentMode}&prod_id=${prodId}`;
        window.location.href = newUrl; // This will refresh the page with the updated URL
      };

      const renderLookupKeyButtons = () => {
        if (lookupKey && lookupKey.startsWith('resume')) {
          return (
            <div className={styles.lookupButtonContainer}>
              <button
                className={`${styles.lookupButton} ${lookupKey === 'resume_standard_monthly' ? styles.lookupButtonSelected : ''}`}
                onClick={() => handleLookupKeyChange('resume_standard_monthly')}
              >
                <span className={styles.planName}>Monthly Plan</span>
                <span className={styles.planPrice}>${(Math.floor(parseFloat(pricingResume['monthly'].original.replace('$', '')) * parseFloat(pricingResume['monthly'].discount) * 100) / 100).toFixed(2)}/month</span>
              </button>
              <button
                className={`${styles.lookupButton} ${lookupKey === 'resume_standard_quarterly' ? styles.lookupButtonSelected : ''}`}
                onClick={() => handleLookupKeyChange('resume_standard_quarterly')}
              >
                <span className={styles.planName}>Quarterly Plan</span>
                <span className={styles.planPrice}>${(Math.floor(parseFloat(pricingResume['quarterly'].original.replace('$', '')) * parseFloat(pricingResume['quarterly'].discount) * 100) / 100).toFixed(2)}/quarter</span>
              </button>
              <button
                className={`${styles.lookupButton} ${lookupKey === 'resume_standard_half_yearly' ? styles.lookupButtonSelected : ''}`}
                onClick={() => handleLookupKeyChange('resume_standard_half_yearly')}
              >
                <span className={styles.planName}>Half-Yearly Plan</span>
                <span className={styles.planPrice}>${(Math.floor(parseFloat(pricingResume['halfYearly'].original.replace('$', '')) * parseFloat(pricingResume['halfYearly'].discount) * 100) / 100).toFixed(2)}/half-year</span>
              </button>
              <button
                className={`${styles.lookupButton} ${lookupKey === 'resume_standard_yearly' ? styles.lookupButtonSelected : ''}`}
                onClick={() => handleLookupKeyChange('resume_standard_yearly')}
              >
                <span className={styles.planName}>Yearly Plan</span>
                <span className={styles.planPrice}>${(Math.floor(parseFloat(pricingResume['yearly'].original.replace('$', '')) * parseFloat(pricingResume['yearly'].discount) * 100) / 100).toFixed(2)}/year</span>
              </button>
            </div>
          );
        }
        return null;
      };

    const checkActiveSubscription =  async () =>{

        const param = {
          'userprofileID':user.username,
          'filter': {
            serviceName: {
              eq:"STAIRAZ Resume Builder AI"
            },
          },         
        }
        try{
    const subscriptionData = await client.graphql({query: subscriptionStatusesByUserprofileID, variables:param} );
    
    setSubscriptionStatus(subscriptionData['data']['subscriptionStatusesByUserprofileID']['items'][0]['active'])
      }catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setSubscriptionStatus(false)
      }
    
    }

  const fetchClientSecret = useCallback(async () => {
    // Call your backend to create a checkout session
    const userData = await fetchUserAttributes();
    const email = userData.email;
    const urlParams = new URLSearchParams(window.location.search);
    const lookup_key = urlParams.get('lookup_key')
    const payment_mode = urlParams.get('payment_mode')
    const prod_id = urlParams.get('prod_id')
    // "prod_QEwFcseH0KpCv0"
    const response = await fetch("https://75f7w41ox2.execute-api.us-east-2.amazonaws.com/stage/create-checkout-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        lookup_key: lookup_key,
        payment_mode:payment_mode,
        prod_id:prod_id,
        user_id: user.username,
        email: email
      })
    });

    const data = await response.json();
    // return "cs_live_a11GFtElGD6d0Htz51UAhnXkWvqJTCbDw6dLxEbcspqvaUiwtOVBjLA2IO_secret_fid1d2BpamRhQ2prcSc%2FJ0lqd2QnKSd2cGd2ZndsdXFsamtQa2x0cGBrYHZ2QGtkZ2lgYSc%2FY2RpdmApJ3BsSGphYCc%2FJ2BoZ2BhYWBhJyknaWR8anBxUXx1YCc%2FJ3Zsa2JpYFpscWBoJyknd2BhbHdgZnFKa0ZqaHVpYHFsamsnPydkaXJkfHYnKSdnZGZuYndqcGthRmppancnPycmZmNjN2MzJ3gl"
    return data.clientSecret; // Return the clientSecret to initialize Stripe
  }, []);

  const handleManageSubscription = async (event) => {
    event.preventDefault();

    try {
      const userData = await fetchUserAttributes();
        const email = userData.email;
      
      const response = await fetch('https://75f7w41ox2.execute-api.us-east-2.amazonaws.com/stage/create-portal-session-userid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          user_id: user.username,
          email:email,
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const portalUrl = data.url; // Assuming the API response contains the URL in a field named 'url'

      // Open the URL in a new window
  
      window.location.href = portalUrl;

    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  };

  useEffect(() => {
    checkActiveSubscription()
    
    if (subscriptionStatus === false) {
        setOptions({fetchClientSecret});
  }
  
  }, [user, subscriptionStatus]);

//   const fetchClientSecret = async () => {
//     // Replace this with a fixed clientSecret value for testing
//     const fixedClientSecret = "cs_live_a15U0ECuO22Iqmhrk70RokkDQl23DyjvJu6IBwts3wF3Haq8sJ4a5NiHit_secret_fid1d2BpamRhQ2prcSc%2FJ0lqd2QnKSd2cGd2ZndsdXFsamtQa2x0cGBrYHZ2QGtkZ2lgYSc%2FY2RpdmApJ3BsSGphYCc%2FJ2BoZ2BhYWBhJyknaWR8anBxUXx1YCc%2FJ3Zsa2JpYFpscWBoJyknd2BhbHdgZnFKa0ZqaHVpYHFsamsnPydkaXJkfHYnKSdnZGZuYndqcGthRmppancnPycmZDRgZ2NjJ3gl";
//     return fixedClientSecret;
//   };
  

  const containerStyle = {
    width: '100%',
  
    backgroundColor: isDarkMode ? '#cff2f6' : '#cff2f6', // Dark mode or light mode background color
    color: isDarkMode ? 'white' : 'black', // Dark mode or light mode text color
    textAlign: 'center',
    padding: '0',
    boxSizing: 'border-box'
  };

  const containerServiceExistStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: 'none',
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    color: 'black',
    backgroundColor: 'lightblue',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
  };

  return (
    <Layout>
    <div style={containerStyle}>
      {subscriptionStatus === null ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <div className="loader"></div> {/* Loading spinner */}
        </div>
      ) : subscriptionStatus ? (
        <div style={containerServiceExistStyle}>
          <p style={{
            fontSize: '18px',
            marginBottom: '20px',
            lineHeight: '1.5'
          }}>
            You already have an active service. Build your resume now or manage your subscription.
          </p>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              style={buttonStyle}
              onClick={() => window.location.href = '/career/service/resume-builder-ai/'}
              onMouseOver={(e) => e.target.style.backgroundColor = 'lightblue'}
              onMouseOut={(e) => e.target.style.backgroundColor = 'lightblue'}
            >
              Build Your Resume
            </button>
            <button
              style={buttonStyle}
              onClick={handleManageSubscription}
              onMouseOver={(e) => e.target.style.backgroundColor = 'lightblue'}
              onMouseOut={(e) => e.target.style.backgroundColor = 'lightblue'}
            >
              Manage Subscription
            </button>
          </div>
        </div>
      ) : options ? (
        <div style={{height:"fit-content",margin:"20px"}}>
        {renderLookupKeyButtons()}
        <div className={styles.featureList}>
          <div className={styles.featureItem}>
            <FaCheckCircle className={styles.featureIcon} />
            <span>Guaranteed refund within 7-day after payment</span>
          </div>
          <div className={styles.featureItem}>
            <FaCheckCircle className={styles.featureIcon} />
            <span>Easy to cancel the service anytime online or by email</span>
          </div>

        </div>
 
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <div className="loader"></div> {/* Loading spinner while options are being set */}
        </div>
      )}
    </div>
  </Layout>
  );
};

export default StairazAuth(CheckoutForm);
